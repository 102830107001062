<template>
  <div class="project america">
    <div class="top">
      <img
        :src="`${QNHost}/Project/AmericaExtraordinary/01.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <img
        class="mobile-show"
        :src="`${QNHost}/Project/AmericaExtraordinary/mobile/01.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <h5 class="no-line">EB-1A杰出人才移民</h5>
        <p class="time">一步到位<span>极速</span>绿卡通道</p>
        <p class="tip">别浪费你的才华</p>
      </div>
    </div>

    <div class="tags">
      <div class="con">
        <div class="tag">安心</div>
        <div class="tag">省心</div>
        <div class="tag">舒心</div>
      </div>
    </div>

    <div class="brief">
      <div class="background-pic">
        <div class="background-media"></div>
      </div>
      <div class="con">
        <div class="img">
          <img
            class="pc-show"
            :src="`${QNHost}/Project/AmericaExtraordinary/02.jpg`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
          <img
            class="mobile-show"
            :src="`${QNHost}/Project/AmericaExtraordinary/mobile/02.png`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
        </div>
        <div class="info">
          <h5>EB-1A杰出人才移民</h5>
          <p class="en">ALIEN OF EXTRAORDINARY ABILITY</p>
          <p class="way">高端人才的自主移民捷径</p>
          <p class="line"></p>
          <p class="txt">
            EB-1A杰出人才移民是职业移民第一优先当中的第一类，被定义为“具有一定专业能力的水准，足以证明申请者是该领域中少数的顶尖人物之一”。
          </p>
          <p class="line"></p>
        </div>
      </div>
      <div class="people">
        <img
          :src="`${QNHost}/Project/bg_none.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <div class="content">
          <h4 class="answer">适宜人群<span>?</span></h4>
          <div class="border no-padding">
            <br />
            <p>
              在科学、技术、教育、社会科学、人文学科、商业、音乐、戏剧、舞蹈、杂技、电影、文学、美术、设计、体育等等方面的杰出专业人才
            </p>
            <br />
          </div>
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="top"><div class="bg-top"></div></div>
      <div class="content">
        <div>
          <h4 class="answer">项目优势<span>?</span></h4>
          <p class="line"></p>
          <div class="text">
            <p>
              <span class="star-five"></span
              >1、职业移民第一类优先，最受美国政府欢迎的移民类型；
            </p>
            <p><span class="star-five"></span>2、申请周期时间短。</p>
            <p>
              <span class="star-five"></span
              >3、一步到位，无需雇主担保，无年龄和学历限制；
            </p>
            <p>
              <span class="star-five"></span
              >4、申请人及其配偶及未满21周岁的子女都可申请永久绿卡。
            </p>
          </div>
          <p class="line"></p>
        </div>
        <img
          class="pc-show"
          :src="`${QNHost}/Project/AmericaExtraordinary/04.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <img
          class="mobile-show"
          :src="`${QNHost}/Project/AmericaExtraordinary/mobile/04.png`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
      </div>
    </div>

    <div class="need">
      <div class="content">
        <h4 class="answer">申请要求<span>?</span></h4>
        <div>
          <p class="line"></p>
          <div class="txt">
            <p>
              一、如果申请人获得过公认的国际奖，如诺贝尔奖、奥斯卡奖等即可。
            </p>
            <p>
              二、如果没有获得过公认的国际奖项，申请人则需要满足下述10条中的至少3项：
            </p>
            <ul>
              <li>
                由于其杰出成就获得过低于国际公认大奖的其他全国性或国际性奖励；
              </li>
              <li>作为必须具有杰出成就方能加入的专业协会或组织之成员；</li>
              <li>其专业成就为专门著作、行业出版物或重要谋体所记载或报导；</li>
              <li>曾经或现在仍然作为其行业评判或评选的评判员或评委委员；</li>
              <li>在有关领域取得过开创性成就；</li>
              <li>
                发表过专业著作或者在行业出版物或主要媒体上撰写过专业文章；
              </li>
              <li>其作品参加过艺术展出；</li>
              <li>身为某一著名机构的负责人或重要人物；</li>
              <li>身处高薪阶层；</li>
              <li>
                如果是表演艺术家，其表演的票房价值或者其唱片、磁带、光碟或影带等的销售有极好的商业收入。
              </li>
            </ul>
          </div>
          <p class="line"></p>
        </div>
      </div>
    </div>

    <div class="question">
      <div>
        <h4>常见问题 <span>Q&A</span></h4>
        <p style="margin-bottom: 0.8rem;">
          Q：发表多少篇文章才能满足EB-1A的申请要求？没有发表过文章怎么办？
        </p>
        <p>
          A：EB-1A对于申请人论文数量是没有明确规定，发表文章的数量只是一个可选择的标准，即表示申请者满足美国杰出人才10条申请标准中的一条，如果不是学者/科研类的申请人，论文发表数量并不是必备条件。同样对于学者
          /科研类的申请人来说，发表文章的数量也是没有要求的。
        </p>
        <br />
        <br />
        <p style="margin-bottom: 0.8rem;">Q：申请EB-1A需要有美国雇主担保吗？</p>
        <p>A：申请美国EB-1A不需要拥有美国雇主担保，也无需永久性工作承诺。</p>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/assets/projectAnimateMixin.js';

export default {
  name: 'canada',
  mixins: [mixin],
};
</script>

<style lang="scss" scoped>
@import "../../assets/project.scss";
.background-media {
  background-image: url($QNHost + "/Project/AmericaExtraordinary/11.jpg");
}
@media screen and (max-width: 750px) {
  .background-media {
    background-image: url($QNHost + "/Project/AmericaExtraordinary/mobile/11.png");
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project america"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/AmericaExtraordinary/01.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaExtraordinary/mobile/01.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"brief"},[_vm._m(2),_c('div',{staticClass:"con"},[_c('div',{staticClass:"img"},[_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaExtraordinary/02.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaExtraordinary/mobile/02.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}})]),_vm._m(3)]),_c('div',{staticClass:"people"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/bg_none.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(4)])]),_c('div',{staticClass:"advantage"},[_vm._m(5),_c('div',{staticClass:"content"},[_vm._m(6),_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaExtraordinary/04.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaExtraordinary/mobile/04.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}})])]),_vm._m(7),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con"},[_c('h5',{staticClass:"no-line"},[_vm._v("EB-1A杰出人才移民")]),_c('p',{staticClass:"time"},[_vm._v("一步到位"),_c('span',[_vm._v("极速")]),_vm._v("绿卡通道")]),_c('p',{staticClass:"tip"},[_vm._v("别浪费你的才华")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags"},[_c('div',{staticClass:"con"},[_c('div',{staticClass:"tag"},[_vm._v("安心")]),_c('div',{staticClass:"tag"},[_vm._v("省心")]),_c('div',{staticClass:"tag"},[_vm._v("舒心")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background-pic"},[_c('div',{staticClass:"background-media"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('h5',[_vm._v("EB-1A杰出人才移民")]),_c('p',{staticClass:"en"},[_vm._v("ALIEN OF EXTRAORDINARY ABILITY")]),_c('p',{staticClass:"way"},[_vm._v("高端人才的自主移民捷径")]),_c('p',{staticClass:"line"}),_c('p',{staticClass:"txt"},[_vm._v(" EB-1A杰出人才移民是职业移民第一优先当中的第一类，被定义为“具有一定专业能力的水准，足以证明申请者是该领域中少数的顶尖人物之一”。 ")]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("适宜人群"),_c('span',[_vm._v("?")])]),_c('div',{staticClass:"border no-padding"},[_c('br'),_c('p',[_vm._v(" 在科学、技术、教育、社会科学、人文学科、商业、音乐、戏剧、舞蹈、杂技、电影、文学、美术、设计、体育等等方面的杰出专业人才 ")]),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"bg-top"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"answer"},[_vm._v("项目优势"),_c('span',[_vm._v("?")])]),_c('p',{staticClass:"line"}),_c('div',{staticClass:"text"},[_c('p',[_c('span',{staticClass:"star-five"}),_vm._v("1、职业移民第一类优先，最受美国政府欢迎的移民类型； ")]),_c('p',[_c('span',{staticClass:"star-five"}),_vm._v("2、申请周期时间短。")]),_c('p',[_c('span',{staticClass:"star-five"}),_vm._v("3、一步到位，无需雇主担保，无年龄和学历限制； ")]),_c('p',[_c('span',{staticClass:"star-five"}),_vm._v("4、申请人及其配偶及未满21周岁的子女都可申请永久绿卡。 ")])]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"need"},[_c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("申请要求"),_c('span',[_vm._v("?")])]),_c('div',[_c('p',{staticClass:"line"}),_c('div',{staticClass:"txt"},[_c('p',[_vm._v(" 一、如果申请人获得过公认的国际奖，如诺贝尔奖、奥斯卡奖等即可。 ")]),_c('p',[_vm._v(" 二、如果没有获得过公认的国际奖项，申请人则需要满足下述10条中的至少3项： ")]),_c('ul',[_c('li',[_vm._v(" 由于其杰出成就获得过低于国际公认大奖的其他全国性或国际性奖励； ")]),_c('li',[_vm._v("作为必须具有杰出成就方能加入的专业协会或组织之成员；")]),_c('li',[_vm._v("其专业成就为专门著作、行业出版物或重要谋体所记载或报导；")]),_c('li',[_vm._v("曾经或现在仍然作为其行业评判或评选的评判员或评委委员；")]),_c('li',[_vm._v("在有关领域取得过开创性成就；")]),_c('li',[_vm._v(" 发表过专业著作或者在行业出版物或主要媒体上撰写过专业文章； ")]),_c('li',[_vm._v("其作品参加过艺术展出；")]),_c('li',[_vm._v("身为某一著名机构的负责人或重要人物；")]),_c('li',[_vm._v("身处高薪阶层；")]),_c('li',[_vm._v(" 如果是表演艺术家，其表演的票房价值或者其唱片、磁带、光碟或影带等的销售有极好的商业收入。 ")])])]),_c('p',{staticClass:"line"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',[_c('h4',[_vm._v("常见问题 "),_c('span',[_vm._v("Q&A")])]),_c('p',{staticStyle:{"margin-bottom":"0.8rem"}},[_vm._v(" Q：发表多少篇文章才能满足EB-1A的申请要求？没有发表过文章怎么办？ ")]),_c('p',[_vm._v(" A：EB-1A对于申请人论文数量是没有明确规定，发表文章的数量只是一个可选择的标准，即表示申请者满足美国杰出人才10条申请标准中的一条，如果不是学者/科研类的申请人，论文发表数量并不是必备条件。同样对于学者 /科研类的申请人来说，发表文章的数量也是没有要求的。 ")]),_c('br'),_c('br'),_c('p',{staticStyle:{"margin-bottom":"0.8rem"}},[_vm._v("Q：申请EB-1A需要有美国雇主担保吗？")]),_c('p',[_vm._v("A：申请美国EB-1A不需要拥有美国雇主担保，也无需永久性工作承诺。")])])])
}]

export { render, staticRenderFns }